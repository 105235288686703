/* ============================================================================
   Page: MyLivingApps Detail
   ========================================================================= */

.mylivingapps-detail {
	& > .row {
		max-width: 1500px;
		margin: 0 auto;
		padding: 0 10px;
	}

	.mylivingapps-detail-app {
		.headline {
			font-size: rem-calc(28px);
		}

		.headline input,
		.headline h1 {
			text-align: left;
			font-size: rem-calc(28px);
			color: $color-primary;
			padding: 0;
			margin: 0;
			border: 0;
			outline: 0;
		}

		.headline-edit-buttons {
			padding-left: rem-calc(10px);

			button,
			.button {
				margin-right: rem-calc(10px);
				margin-bottom: 0;

				.font-icon:before {
					font-size: rem-calc(20px);
				}
			}
		}

		.headline-limit-wrapper {
			font-size: rem-calc(16px);
			color: $color-gray-chateau;
			padding-left: rem-calc(10px);
			display: none;
		}

		.image {
			img {
				max-width: 100%;
			}
		}

		.description textarea {
			min-height: 0;
			border: 0;
			width: 100%;
			resize: none !important;
		}

		.description-limit-wrapper {
			font-size: rem-calc(16px);
			color: $color-gray-chateau;
			display: none;
		}

		.tags {

			button,
			.button {
				.font-icon {
					padding-right: rem-calc(5px);
				}
			}
		}

		.contextmenu-button {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
		}
	}

	.mylivingapps-detail-appinfo,
	.mylivingapps-detail-catalog,
	.mylivingapps-detail-embed {
		.headline {
			font-size: rem-calc(24px);
		}
	}

	.mylivingapps-detail-appinfo {
		.row .row .columns {
			margin-top: 0;
			margin-bottom: 0;
		}

		h4 {
			color: $color-grey;
			margin: 0;
		}

		p {
			margin: 0;

			a {
				font-weight: $semi-bold;
				color: $color-primary;

				&:hover {
					color: $color-primary-hover;
				}
			}
		}
	}

	.mylivingapps-detail-catalog {
		.row .row .columns {
			margin-top: 0;
			margin-bottom: 0;
		}

		h4 {
			color: $color-grey;
			margin: 0;
		}

		p {
			margin: 0;

			a {
				font-weight: $semi-bold;
				color: $color-primary;

				&:hover {
					color: $color-primary-hover;
				}
			}
		}

		button,
		.button {
			margin: rem-calc(10px) 0 0;
		}
	}

	.mylivingapps-detail-dataInput {
		padding-top: 2px;
		padding-bottom: 2px;
		padding-right: $grid-gutter-pixel;
		padding-left: $grid-gutter-pixel;

		.edit-button {
			color: #fff;
			opacity: 0.6;
			@include single-transition(opacity);
			margin: 0 8px 0 0;

			&:hover {
				opacity: 0.8;
			}
		}

		button,
		.button {
			margin: 0;
		}

		@media screen and (min-width: $break-small) {
			.medium-6:first-child {
				border-right: 1px solid rgba(255, 255, 255, 0.8);
			}

			.medium-6:last-child {
				border-left: 1px solid rgba(255, 255, 255, 0.8);
			}
		}
	}

	.mylivingapps-detail-separator {
		text-align: center;

		.font-icon:before {
			font-size: 60px;
			line-height: 30px;
			color: #cbd6da;
		}
	}

	.mylivingapps-detail-connectedApps {
		.columns {
			margin-top: 0;
			margin-bottom: 0;
		}

		.mylivingapps-detail-connectedApps-item {
			display: inline-block;
			margin-left: rem-calc(16px);

			img {
				max-height: 30px;
			}

			a {
				display: inline-block;
				margin-left: rem-calc(8px);
			}
		}
	}
}