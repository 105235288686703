/* ============================================================================
   Abstract: Colors
   ========================================================================= */

/**
 * Standard Colors
 */
$color-light: #fff !default;
$color-dark: #333 !default;

/**
 * Grey
 */
$color-athens-gray: #f0f3f5 !default;
$color-dove-gray: #666 !default;
$color-gray-chateau: #a4a8ab !default;
$color-river-bed: #46535b !default;
$color-grey: #8a8d99 !default;
$color-iron: #dde1e4 !default;
$color-iron-dark: darken($color-iron, 6%) !default;
$color-iron-x-dark: darken($color-iron, 14%) !default;
$color-porcelain: #ebeef0 !default;
$color-porcelain-dark: darken($color-porcelain, 8%) !default;
$color-mercury-light: lighten(#e2e2e2, 8%) !default;
$color-mercury: #e2e2e2 !default;
$color-mercury-dark: darken($color-mercury, 7%) !default;
$color-cutty-sark: #57707a !default;
$color-cutty-sark-dark: darken($color-cutty-sark, 8%) !default;
$color-oslo-gray: #93979a !default;
$color-oslo-gray-dark: darken($color-oslo-gray, 8%) !default;

/**
 * Red
 */
$color-red: #b10f0f !default;
$color-red-dark: darken($color-red, 5%) !default;
$color-alizarin-crimson: #e42f2f !default;
$color-alizarin-crimson-dark: darken($color-alizarin-crimson, 8%) !default;

/**
 * Blue
 */
$color-blue: #008bd2 !default;
$color-blue-dark: darken($color-blue, 8%) !default;
$color-category-blue: #016c9b !default;
$color-light-blue: #ace2fe !default;

/**
 * Orange
 */
$color-orange-light: lighten(#f39325, 8%) !default;
$color-orange: #f39325 !default;
$color-orange-dark: darken(#f39325, 8%) !default;
$color-category-orange: #f19a4d !default;
$color-category-orange-dark: darken($color-category-orange, 5%) !default;

/**
 * Green
 */
$color-green: #afcb37 !default;
$color-green-dark: darken($color-green, 8%) !default;

/**
 * Cyan
 */
$color-category-cyan: #1abc9c !default;
$color-category-cyan-dark: darken($color-category-cyan, 5%) !default;

/**
 * Pink
 */
$color-category-pink: #c26783 !default;
$color-category-pink-dark: darken($color-category-pink, 5%) !default;

$menubar-menu-icon-position: right !default;