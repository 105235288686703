// This is the main css file. All other files will be imported!

// Make sure the charset is set appropriately
@charset "UTF-8";

@layer la_assets_theme, la_assets_base;

/*----------  Abstracts  ----------*/
@import 'abstracts/abstracts';


/*----------  Theme  ----------*/
@layer la_assets_theme {
	@import '~scss/theme';
}

/*----------  Base  ----------*/
@layer la_assets_base {
	/*----------  Vendors  ----------*/
	@import '~magnific-popup/src/css/main';
	@import 'vendors/foundation';
	@import 'vendors/responsive_tables';
	@import 'vendors/tables';
	// @import 'vendors/animate';
	// @import '../lib/font-awesome/scss/font-awesome';	@import 'base/normalize';
	@import 'base/fonts';
	@import 'base/iconfont';
	@import 'base/utils';
	@import 'base/layout_main';
	@import 'base/forms';
	@import 'base/cms_responsive';
	@import 'base/userArea';
	@import 'base/cookie-notice';
	@import 'base/print';
	/*----------  Components  ----------*/
	@import 'components/head-images';
	@import 'components/nav-fl';
	@import 'components/footer';
	@import 'components/button';
	@import 'components/panel';
	@import 'components/card';
	@import 'components/contextmenu';
	@import 'components/logo';
	@import 'components/embed';
	@import 'components/fileupload';
	@import 'components/notify-row';
	@import 'components/tabs';
	@import 'components/typed_views_tabs';
	@import 'components/typed_views_wizard';
	@import 'components/tag';
	@import 'components/usermenu';
	@import 'components/ili';
	@import 'components/global-notification';
	@import 'components/menubar';
	@import 'components/head-overlay';
	@import 'components/block-ui';
	@import 'components/filter';
	@import 'components/category';
	@import 'components/magnific-popup';
	@import 'components/category-manager';
	@import 'components/apppanel';
	@import 'components/datamanagement-table';
	@import 'components/app-icon';
	@import 'components/waf';
	@import 'components/livingapps-form';
	@import 'components/sidemenu';
	@import 'components/sidenav';
	@import 'components/data-actions';
	@import 'components/attachments';
	/*----------  Pages  ----------*/
	@import 'pages/account-login';
	@import 'pages/account-pwdconfirm';
	@import 'pages/account-pwdrecovery';
	@import 'pages/account-register';
	@import 'pages/account-registerconfirm';
	@import 'pages/catalog-detail';
	@import 'pages/catalog-overview';
	@import 'pages/mylivingapps-data-configuration';
	@import 'pages/mylivingapps-detail';
	@import 'pages/mylivingapps-overview';
	@import 'pages/mylivingapps-publish';
	@import 'pages/mylivingapps-evaluation';
	@import 'pages/user-owndata';
	@import 'pages/profile';
	@import 'pages/contactpage';
	@import 'pages/terms';
}